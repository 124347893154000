import { initScript } from "../../site/baseScript";

const Teaser = {
  setStaticVariables() {
    this.fullscaleTeaserSelector = '.teaser.h-fullscale';
    this.arrowClassName = "arrow";
  },

  setDynamicVariables() {
    this.fullscaleTeaserEl = document.querySelector(this.fullscaleTeaserSelector);
  },

  injectArrow() {
    const arrowEl = document.createElement('div');
    arrowEl.classList.add(this.arrowClassName);
    arrowEl.addEventListener('click', () => this.handleArrow());
    this.fullscaleTeaserEl.appendChild(arrowEl);
  },

  handleArrow() {
    const { marginTop, paddingTop } = window.getComputedStyle(this.fullscaleTeaserEl
      .nextElementSibling);
    let nextElSpacing = [marginTop, paddingTop]
      .reduce((previousValue, currentValue) => previousValue + Number(currentValue.replace('px', '')), 0);

    window.scrollBy({
      top: this.fullscaleTeaserEl.offsetHeight - nextElSpacing,
      behavior: 'smooth',
    });
  },

  init() {
    this.setStaticVariables();
    this.setDynamicVariables();

    if (this.fullscaleTeaserEl) {
      this.injectArrow();
    }
  },
};

initScript(Teaser);