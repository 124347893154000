import { initScript } from "../../site/baseScript";

const LegalPage = {
	setStaticVariables() {
    this.legalPageContainerSelector = '.cmp-legal-page';
	},
	
	setDynamicVariables() {
    this.legalPageContainer = document.querySelector(this.legalPageContainerSelector);
	},

  addTableWrapper() {
    this.legalPageContainer.querySelectorAll('table')
      .forEach(tableEl => {
        tableEl.outerHTML = `
          <div class="table-wrapper">
            ${tableEl.outerHTML}
          </div>
        `;
      });
  },

	init() {
		this.setStaticVariables();
		this.setDynamicVariables();
    if (this.legalPageContainer) {
      this.addTableWrapper();
    }
	}
};

initScript(LegalPage);