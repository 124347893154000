import { getContentFragmentParams, initScript, transformInternalUrl } from "../../site/baseScript";

const TeamMember = {
  setStaticVariables() {
    this.teamMemberSelector = '.cmp-team-member';
    this.teaserContentSelector = '.cmp-teaser__content';
    this.teaserTitleSelector = '.cmp-teaser__title';
    this.teaserDescriptionSelector = '.cmp-teaser__description';
    this.teaserDescriptionClass = 'cmp-teaser__description';
    this.contentFragmentItemSelector = '.contentfragment .cmp-contentfragment';
    this.contentFragmentListSelector = '.contentfragmentlist';
    this.contentFragmentItemsSelector = '.contentfragmentlist .cmp-contentfragment';
    this.aemGridSelector = '.aem-Grid';
    this.h6RegularClass = 'h6--regular';

    this.goToLinkedInText = '→ GO TO LINKEDIN';
  },

  setDynamicVariables() {
    this.teamMemberEl = document.querySelector(this.teamMemberSelector);
    if (this.teamMemberEl) {
      this.teaserContentEl = this.teamMemberEl.querySelector(this.teaserContentSelector);
      this.teaserTitleEl = this.teamMemberEl.querySelector(this.teaserTitleSelector);
      this.teaserDescriptionEl = this.teamMemberEl.querySelector(this.teaserDescriptionSelector);
      this.contentFragmentListEl = this.teamMemberEl.querySelector(this.contentFragmentListSelector);
      this.contentFragmentItem = this.teamMemberEl.querySelector(this.contentFragmentItemSelector);
      this.contentFragmentItems = this.teamMemberEl.querySelectorAll(this.contentFragmentItemsSelector);
      this.aemGridEl = this.contentFragmentListEl.closest(this.aemGridSelector);
    }
  },

  setContainerHeight() {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { target } = entry;
        const { parentElement, clientHeight } = target;
        parentElement.style.height = `${clientHeight}px`;
      }
    });
    resizeObserver.observe(this.aemGridEl);
  },

  getTimeline(timeline) {
    const docEl = document.createElement('div');
    docEl.innerHTML = timeline;
    docEl.querySelectorAll('br').forEach(br => br.remove());
    return docEl.innerHTML;
  },

  renderTeamMemberInformation() {
    const {
      title,
      jobTitle,
      photoPath,
      timeline,
      description,
      linkedinProfile,
      personWebsiteUrl
    } = {
      title: this.contentFragmentItem.querySelector('.cmp-contentfragment__title').innerText,
      ...getContentFragmentParams(this.contentFragmentItem, ['jobTitle', 'photoPath', 'timeline', 'description', 'linkedinProfile', 'personWebsiteUrl'])
    };

    this.personWebsiteUrl = personWebsiteUrl;
    this.teaserTitleEl.innerHTML = title;

    if (this.teaserDescriptionEl) {
      this.teaserDescriptionEl.classList.add(this.h6RegularClass);
      this.teaserDescriptionEl.classList.remove(this.teaserDescriptionClass);
      this.teaserDescriptionEl.innerHTML = `<p>${jobTitle}</p>`;
    } else {
      const teaserDescriptionEl = document.createElement('div');
      teaserDescriptionEl.classList.add(this.h6RegularClass);
      teaserDescriptionEl.innerHTML = `<p>${jobTitle}</p>`;
      this.teaserContentEl.appendChild(teaserDescriptionEl);
    }

    const teamMemberItemEl = document.createElement('div');
    teamMemberItemEl.classList.add('cmp-team-member__item');
    teamMemberItemEl.innerHTML = `
      <div class="cmp-team-member__item-section">
        <div class="cmp-team-member__item--image">
          <img src="${photoPath}" alt="${title}" />
        </div>
        <div class="cmp-team-member__item--timeline">${this.getTimeline(timeline)}</div>
      </div>
      <div class="separator"></div>
      <div class="cmp-team-member__item-section">
        <div class="cmp-team-member__item--description">${description}</div>
        <a href="${linkedinProfile}" target="_blank">${this.goToLinkedInText}</a>
      </div>
    `;
    const separatorEl = document.createElement('div');
    separatorEl.classList.add('cmp-team-member__separator');
    separatorEl.innerHTML = '<span></span>';
    this.contentFragmentItem.innerHTML = '';
    this.contentFragmentItem.appendChild(teamMemberItemEl);
    this.contentFragmentItem.appendChild(separatorEl);
  },

  renderOurTeamNavigation() {
    let personWebsiteUrlArray = [];
    this.contentFragmentItems.forEach(contentFragmentItem => {
      const { personWebsiteUrl } = {
        title: contentFragmentItem.querySelector('.cmp-contentfragment__title').innerText,
        ...getContentFragmentParams(contentFragmentItem, ['personWebsiteUrl'])
      };
      personWebsiteUrlArray.push(personWebsiteUrl);
      contentFragmentItem.style.display = 'none';
    });

    const currentIndex = personWebsiteUrlArray.findIndex(current => current === this.personWebsiteUrl);
    if (currentIndex !== -1) {
      this.prevElUrl = personWebsiteUrlArray[currentIndex - 1];
      this.nextElUrl = personWebsiteUrlArray[currentIndex + 1];
    }

    const ourTeamNavigationEl = document.createElement('div');
    ourTeamNavigationEl.classList.add('cmp-team-member__navigation');
    ourTeamNavigationEl.innerHTML = `
      ${this.prevElUrl ? (
        `<a class="cmp-team-member__navigation-link--left" href="${transformInternalUrl(
          this.prevElUrl
        )}">PREVIOUS</a>`
      ) : '<span></span>'}
      ${this.nextElUrl ? (
        `<a class="cmp-team-member__navigation-link--right"  href="${transformInternalUrl(
          this.nextElUrl
        )}">NEXT</a>`
      ) : '<span></span>'}
    `;
    this.contentFragmentListEl.appendChild(ourTeamNavigationEl);
  },

  init() {
    this.setStaticVariables();
    this.setDynamicVariables();

    if (this.teamMemberEl) {
      this.setContainerHeight();
      this.renderTeamMemberInformation();
      this.renderOurTeamNavigation();
    }
  },
};
initScript(TeamMember);