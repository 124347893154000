import { getContentFragmentParams, initScript, transformInternalUrl } from '../../site/baseScript';

const OurPortfolio = {
	setStaticVariables() {
    this.ourPortfolioSelector = '.cmp-our-portfolio';
	},
	
	setDynamicVariables() {
    this.ourPortfolioEl = document.querySelector(this.ourPortfolioSelector);
    if (this.ourPortfolioEl) {
      this.contentFragmentItems = this.ourPortfolioEl.querySelectorAll('.contentfragment');
    }
	},

  renderOurPortfolioItems() {
    this.contentFragmentItems.forEach(contentFragmentItem => {
      const articleEl = contentFragmentItem.querySelector('article');
      const {
        title,
        logoPath,
        colorLogoPath,
        internalUrl
      } = {
        title: contentFragmentItem.querySelector('.cmp-contentfragment__title').innerText,
        ...getContentFragmentParams(contentFragmentItem, ['logoPath', 'colorLogoPath', 'internalUrl'])
      };
      const anchorEl = document.createElement('a');
      anchorEl.classList.add('cmp-our-portfolio__item');
      anchorEl.href = transformInternalUrl(internalUrl);
      anchorEl.innerHTML = `
        <img src="${logoPath}" alt="${title}" class="cmp-our-portfolio__item--logo" />
        <img src="${colorLogoPath}" alt="${title}" class="cmp-our-portfolio__item--logo-hover" />
      `;
      articleEl.innerHTML = '';
      articleEl.appendChild(anchorEl);
    });
  },

	init() {
		this.setStaticVariables();
		this.setDynamicVariables();

    if (this.ourPortfolioEl) {
      this.renderOurPortfolioItems();
    }
	}
};

initScript(OurPortfolio);