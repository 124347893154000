import { initScript, transformInternalUrl } from '../../site/baseScript';

const InvestmentAreas = {
  setStaticVariables() {
    this.investmentAreasSelector = '.cmp-investment-areas';
    this.clickableImageSelector = '.image a';
  },

  setDynamicVariables() {
    this.investmentAreasEl = document.querySelector(this.investmentAreasSelector);
  },

  attachEvents() {
    window.addEventListener('resize', () => this.setCaptionPosition());
  },

  setCaptionPosition() {
    let heights = [];
    const captionItems = this.investmentAreasEl.querySelectorAll('.cmp-image__title');

    captionItems.forEach(item => {
      item.style.height = 'auto';
      heights.push(item.clientHeight);
    });
    
    const maxHeight = Math.max(...heights);
    captionItems.forEach(item => item.style.height = `${maxHeight}px`);
  },

  setUrlLink() {
    this.investmentAreasEl.querySelectorAll(this.clickableImageSelector)
      .forEach(anchorEl => {
        const { href } = anchorEl;
        anchorEl.href = transformInternalUrl(href);
      })
  },

  init() {
    this.setStaticVariables();
    this.setDynamicVariables();

    if (this.investmentAreasEl) {
      this.attachEvents();
      this.setCaptionPosition();
      this.setUrlLink();
    }
  }
};

initScript(InvestmentAreas);
