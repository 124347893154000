import { getContentFragmentParams, initScript, transformInternalUrl } from "../../site/baseScript";

const OurTeam = {
  setStaticVariables() {
    this.ourTeamSelector = '.cmp-our-team';
  },

  setDynamicVariables() {
    this.ourTeamEl = document.querySelector(this.ourTeamSelector);
    if (this.ourTeamEl) {
      this.contentFragmentItems = this.ourTeamEl.querySelectorAll('.cmp-contentfragment');
    }
  },

  renderOurTeamItems() {
    this.contentFragmentItems.forEach(contentFragmentItem => {
      const {
        title,
        photoPath,
        name,
        jobTitle,
        personWebsiteUrl
      } = {
        title: contentFragmentItem.querySelector('.cmp-contentfragment__title').innerText,
        ...getContentFragmentParams(contentFragmentItem, ['photoPath', 'name', 'jobTitle', 'personWebsiteUrl'])
      };

      const ourTeamItemEl = document.createElement('a');
      ourTeamItemEl.classList.add('cmp-our-team__item');
      ourTeamItemEl.href = transformInternalUrl(personWebsiteUrl);
      ourTeamItemEl.innerHTML = `
        <div class="cmp-our-team__item--image">
          <img src="${photoPath}" alt="${title}" />
        </div>
        <span class="cmp-our-team__item--name body-big--semibold">${name}</span>
        <span class="cmp-our-team__item--job-title body-big--regular">${jobTitle}</span>
      `;
      contentFragmentItem.innerHTML = '';
      contentFragmentItem.appendChild(ourTeamItemEl);
    });
  },

  init() {
		this.setStaticVariables();
		this.setDynamicVariables();

    if (this.ourTeamEl) {
      this.renderOurTeamItems();
    }
  },
};
initScript(OurTeam);