import { initScript } from "../../site/baseScript";

const Navigation = {
	setStaticVariables() {
		this.headerFixedPosition = 'fixed';
		this.headerNavOpenClass = 'nav-open';
		this.hamburgerMenuClass = 'nav-hamburger-menu';
		this.navRevertClass = 'nav-revert';
	},

	setDynamicVariables() {
		this.header = document.querySelector('.header');
		this.teaserEl = document.querySelector('.header + .container .teaser');
		this.navigation = this.header.querySelector('nav');
	},

	attachEvents() {
		document.addEventListener('scroll', () => this.onDocumentScroll());
	},

	initHamburgerMenu() {
		const hamburgerMenuEl = document.createElement('span');
		hamburgerMenuEl.classList.add(this.hamburgerMenuClass);
		hamburgerMenuEl.addEventListener('click', () => {
			document.body.classList.toggle(this.headerNavOpenClass);
		});
		this.navigation.appendChild(hamburgerMenuEl);

		if (!this.teaserEl) {
			document.body.classList.add(this.navRevertClass);
		}
	},

	onDocumentScroll() {
		if (this.header) {
			const windowOffset = window.pageYOffset;
			const headerHeight = this.header.clientHeight;

			if (windowOffset > headerHeight) {
				this.header.classList.add(this.headerFixedPosition);
			} else {
				this.header.classList.remove(this.headerFixedPosition);
			}
		}
	},

	init() {
		this.setStaticVariables();
		this.setDynamicVariables();
		this.initHamburgerMenu();
		this.onDocumentScroll();
		this.attachEvents();
	}
};

initScript(Navigation);