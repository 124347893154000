import { initScript } from '../../site/baseScript';
import { BaseForm } from '../form/_form';

const ContactUs = {
	setStaticVariables() {
    this.id = '#contact-us';
    this.customFieldNames = ['termsAndConditions'];
    this.checkedClassName = 'checked';
  },

	init() {
    this.setStaticVariables();
    this.baseForm = BaseForm.init({
      id: this.id,
      customFieldNames: this.customFieldNames,
      bindCustomEvents: this.bindCustomEvents,
    });
    if (this.baseForm.formEl) {
      this.addPlaceholders();
      this.adjustCheckboxButton();
    }
	},

  bindCustomEvents(baseForm) {
    const customMessageClass = 'custom-message';
    const errorMessageClass = 'custom-error-message';
    const termsAndConditionsEl = baseForm.formEl.querySelector('[name="termsAndConditions"]');
    if (termsAndConditionsEl) {
      const fieldsetEl = termsAndConditionsEl.closest('fieldset');
      const errorMessageEl = document.createElement('span');
      errorMessageEl.classList.add(customMessageClass, errorMessageClass);
      errorMessageEl.innerText = 'Field is required';
      fieldsetEl.appendChild(errorMessageEl);
      termsAndConditionsEl.parentElement.addEventListener('click', (e) => {
        e.stopPropagation();
        const { currentTarget } = e;
        const { name, checked } = currentTarget.querySelector('input');
        if (checked) {
          baseForm.formData[name] = true;
          errorMessageEl.classList.remove(errorMessageClass);
        } else {
          delete(baseForm.formData[name]);
          errorMessageEl.classList.add(errorMessageClass);
        }
      });
    }
  },

  addPlaceholders() {
    const { formEl } = this.baseForm;
    const formControls = formEl.querySelectorAll('.cmp-form-text');
    formControls.forEach(formControl => {
      const labelEl = formControl.querySelector('label');
      if (labelEl) {
        const controlEl = formControl.querySelector('input, textarea');
        if (controlEl) {
          const { required = false } = controlEl;
          controlEl.setAttribute('placeholder', `${labelEl.innerText}${required ? ' *' : ''}`);
        }
      }
    });
  },

  adjustCheckboxButton() {
    const { formEl } = this.baseForm;
    formEl.querySelectorAll('input[type="checkbox"]')
      .forEach(checkboxEl => {
        checkboxEl.addEventListener('click', ({ target }) => {
          const { checked } = target;
          if (checked) {
            target.parentElement.classList.add(this.checkedClassName);
          } else {
            target.parentElement.classList.remove(this.checkedClassName);
          }
        });
      });
  }
};

initScript(ContactUs);