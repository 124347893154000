import { getContentFragmentParams, initScript, transformInternalUrl } from "../../site/baseScript";

const OurPortfolioGrid = {
	setStaticVariables() {
    this.ourPortfolioGridSelector = '.cmp-our-portfolio-grid';
    this.contentFragmentListSelector = '.contentfragmentlist';
	},
	
	setDynamicVariables() {
    this.ourPortfolioGridEl = document.querySelector(this.ourPortfolioGridSelector);
    if (this.ourPortfolioGridEl) {
      this.contentFragmentItems = this.ourPortfolioGridEl.querySelectorAll('.cmp-contentfragment');
      this.contentFragmentList = this.ourPortfolioGridEl.querySelector(this.contentFragmentListSelector);
    }
	},

  getOurPortfolioData() {
    let ourPortfolioData = [];
    this.contentFragmentItems.forEach(contentFragmentItem => {
      ourPortfolioData.push({
        name: contentFragmentItem.querySelector('.cmp-contentfragment__title').innerText,
        ...getContentFragmentParams(contentFragmentItem, ['logoPath', 'colorLogoPath', 'internalUrl', 'title', 'websiteUrl', 'imageComposition', 'description', 'bgImage'])
      });
    });
    return ourPortfolioData;
  },

  renderOurPortfolioPage() {
    const ourPortfolioData = this.getOurPortfolioData();
    this.contentFragmentList.querySelectorAll('article')
      .forEach(articleEl => articleEl.style.display = 'none');
    this.contentFragmentList.appendChild(this.createOurPorftolioButtonsSection(ourPortfolioData));
    ourPortfolioData.forEach((portfolioItem, index) => {
      this.contentFragmentList.appendChild(this.createOurPorftolioProductItem(portfolioItem, index))
    });
  },

  createOurPorftolioButtonsSection(data) {
    const ourPortfolioButtonsSection = document.createElement('div');
    ourPortfolioButtonsSection.classList.add('cmp-our-portfolio-grid__buttons', 'container', 'theme-gray', 'no-fullwidth', 'p-small');
    ourPortfolioButtonsSection.innerHTML = `
      <div class="cmp-container">
        ${data.map(({ name, logoPath, colorLogoPath, internalUrl }) => (
          `
            <a href="${transformInternalUrl(internalUrl)}">
              <img src="${logoPath}" alt="${name}" class="cmp-our-portfolio__item--logo" />
              <img src="${colorLogoPath}" alt="${name}" class="cmp-our-portfolio__item--logo-hover" />
            </a>
          `
        )).join('')}
      </div>
    `;
    return ourPortfolioButtonsSection;
  },

  createOurPorftolioProductItem(item, index) {
    const ourPortfolioItemSection = document.createElement('div');
    ourPortfolioItemSection.classList.add('cmp-our-portfolio-grid__item', 'container', 'theme-white');
    ourPortfolioItemSection.innerHTML = `
      ${this.addPortfolioProductInfo(item)}
      ${this.addPortfolioProductColumns(item, index)}
      <img src="${item.bgImage}" alt="${item.name}" />
    `;
    return ourPortfolioItemSection;
  },

  addPortfolioProductInfo({ name, colorLogoPath, internalUrl, title, websiteUrl }) {
    const anchorId = internalUrl.substring(internalUrl.indexOf('#') + 1);
    return `
      <div id=${anchorId} class="cmp-our-portfolio-grid__item--info container theme-width no-fullwidth p-small">
        <div class="cmp-container">
          <img src="${colorLogoPath}" alt="${name}" class="cmp-our-portfolio-grid__item--info-logo" />
          <h5 class="cmp-our-portfolio-grid__item--info-title h5--regular">${title}</h5>
          <a href=${websiteUrl} target="_blank" class="cmp-our-portfolio-grid__item--info-link">Go To Website</a>
        </div>
      </div>
    `;
  },

  addPortfolioProductColumns({ name, imageComposition, description }, index) {
    const content = [
      `<img src="${imageComposition}" alt="${name}" class="cmp-our-portfolio-grid__item--columns-image" />`,
      `<div class="separator"></div>`,
      `
        <div class="text">
          <div class="cmp-text">${description}</div>
        </div>
      `
    ];

    if (index % 2 !== 0)  {
      content.reverse();
    }
    return `
      <div class="cmp-our-portfolio-grid__item--columns container theme-width no-fullwidth p-small cmp-text-image ${index % 2 !== 0 ? 'reverse-order' : ''}">
        <div class="cmp-container">${content.join('')}</div>
      </div>
    `;
  },

	init() {
		this.setStaticVariables();
		this.setDynamicVariables();

    if (this.ourPortfolioGridEl) {
      this.renderOurPortfolioPage();
    }
	}
};

initScript(OurPortfolioGrid);