const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const BaseForm = {
	setStaticVariables() {
        this.formSelector = 'form.cmp-form';
        this.formButtonSelector = 'button.cmp-form-button';
        this.requiredFieldsSelector = '[required]';
        this.invalidClass = 'invalid';
        this.buttonClass = 'cmp-button';
        this.requiredClass = 'required';
        this.errorClass = 'error';
        this.constraintClass = 'constraint';
        this.errorMessageClass = 'error-message';
        this.constraintMessageClass = 'constraint-message';
        this.formData = {};
	},
	
	setDynamicVariables() {
        this.formEl = document.querySelector(`${this.formSelector}${this.formId}`);
        this.formValid = false;
	},

    initializeForm() {
        if (this.formEl) {
            this.bindCustomEvents(this);
            this.initializeTextFields();
            this.initializeFormButtons();
        }
    },

    initializeTextFields() {
        this.formEl.querySelectorAll(this.requiredFieldsSelector)
            .forEach(requiredField => {
                this.addRequiredAsterisk(requiredField);
                this.addInvalidMessages(requiredField);
                requiredField.addEventListener('input', (e) => this.onInputChange(e, requiredField));
            });
    },
    
    initializeFormButtons() {
        this.formEl.querySelectorAll(this.formButtonSelector)
            .forEach(buttonEl => {
                const { type } = buttonEl;
                buttonEl.classList.add(this.buttonClass);

                if (type.toLowerCase() === 'submit') {
                    buttonEl.addEventListener('click', (e) => this.onSubmitButtonClick(e));
                }
            });
    },

    onInputChange({ target }, { parentElement }) {
        const { value, type, required } = target;
        if (required) {
            if (value === '') {
                parentElement.classList.add(this.errorClass);
            } else {
                parentElement.classList.remove(this.errorClass);
            }
        } else {
            parentElement.classList.remove(this.errorClass);
        }

        if (type === 'email' && value !== '' && !this.checkEmailFormat(value)) {
            parentElement.classList.add(this.constraintClass);
        } else {
            parentElement.classList.remove(this.constraintClass);
        }
    },

    onSubmitButtonClick(e) {
        if (!this.formValid) {
            e.preventDefault();
            let requiredFieldLabels = [...this.customFieldNames];
            this.formEl.querySelectorAll(this.requiredFieldsSelector)
                .forEach(requiredField => {
                    const { name, value, type } = requiredField;
                    requiredFieldLabels.push(name);
    
                    if (type && type === 'email') {
                        if (this.checkEmailFormat(value)) {
                            this.formData[name] = value;
                        }
                    } else {
                        delete(this.formData[name]);
                        this.formData = {
                            ...this.formData,
                            ...(value !== '' && { [name]: value })
                        }
                    }
                });
    
            const isRequiredFieldsCompleted = this.checkFormValidation(requiredFieldLabels, this.formData);
            if (isRequiredFieldsCompleted) {
                this.formEl.classList.remove(this.invalidClass);
                this.formValid = true;
                e.target.click();
            } else {
                this.formEl.classList.add(this.invalidClass);
            }
        }
    },

    addRequiredAsterisk(requiredField) {
        const { parentElement } = requiredField;
        const labelEl = parentElement.querySelector('label');
        if (labelEl) {
            labelEl.classList.add(this.requiredClass);
        }
    },

    addInvalidMessages(requiredField) {
        const { parentElement } = requiredField;
        const { cmpRequiredMessage, cmpConstraintMessage } = parentElement.dataset;
        const errorMessageEl = document.createElement('span');
        errorMessageEl.classList.add(this.errorMessageClass);
        errorMessageEl.innerText = cmpRequiredMessage;
        parentElement.appendChild(errorMessageEl);

        if (cmpConstraintMessage) {
            const constraintMessageEl = document.createElement('span');
            constraintMessageEl.classList.add(this.constraintMessageClass);
            constraintMessageEl.innerText = cmpConstraintMessage;
            parentElement.appendChild(constraintMessageEl);
        }
    },

    checkEmailFormat(value) {
        return EMAIL_REGEX.test(value);
    },

    checkFormValidation(fields) {
        return fields.map(label => {
            const isValid = Boolean(Object.keys(this.formData)
                .find(formLabel => formLabel === label));
            const field = this.formEl.querySelector(`[name="${label}"]`);
            if (field) {
                const { parentElement } = field;
                if (isValid) {
                    parentElement.classList.remove(this.errorClass);
                    parentElement.classList.remove(this.constraintClass);
                } else {
                    const { type, value, required } = field;
                    if (type === 'email' && value !== '' && !this.checkEmailFormat(value)) {
                        parentElement.classList.add(this.constraintClass);
                    }
                    if (required && value === '') {
                        parentElement.classList.add(this.errorClass);
                    }
                }
            }
            return isValid;
        }).every(label => label);
    },

	init({ id, customFieldNames, bindCustomEvents }) {
        this.formId = id;
        this.customFieldNames = customFieldNames;
        this.bindCustomEvents = bindCustomEvents;
		this.setStaticVariables();
		this.setDynamicVariables();
        this.initializeForm();
        return this;
	}
};