const REGEX_PATTERN = new RegExp('\/content\/pmequitypartner\/[a-zA-Z_-]{2,5}', 'mi');
const HTML_EXTENSION = '.html';

export const initScript = (component) => {
  if(document.readyState !== 'loading') {
    component.init();
  } else {
    document.addEventListener('DOMContentLoaded', function () {
      component.init();
    });
  }
}

export const transformInternalUrl = (url) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (typeof Granite !== 'undefined' || urlParams.has('wcmmode')) {
    if (isInternal(url)) {
      const newUrl = url.replace('#', `${HTML_EXTENSION}#`);
      return `${newUrl}${hasHTMLExtension(newUrl) ? '' : HTML_EXTENSION}`;
    }
    return url;
  }
  return url.replace(REGEX_PATTERN, '');
}

export const getContentFragmentParams = (contentFragment, array) => {
  const prefixClass = 'cmp-contentfragment__element--';

  let params = {};
  array.map(paramName => {
    const paramEl = contentFragment.querySelector(`.${prefixClass}${paramName} .cmp-contentfragment__element-value`);
    return { [paramName]: paramEl ? paramEl.innerHTML.trim() : '' };
  }).forEach(param => {
    params = {
      ...params,
      ...param
    }
  });
  return params;
}

const isInternal = (url) => {
  return REGEX_PATTERN.test(url);
}

const hasHTMLExtension = (url) => {
  return url.indexOf(HTML_EXTENSION) !== -1;
}