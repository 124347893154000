import { initScript } from '../../site/baseScript';

const Video = {
  setStaticVariables() {
    this.videoSelector = '.cmp-video';
  },

  setDynamicVariables() {
    this.videoEl = document.querySelector(this.videoSelector);
    if (this.videoEl) {
      this.imageEl = this.videoEl.querySelector('.image');
      this.iframeEl = this.videoEl.querySelector('.embed iframe');
    }
  },

  attachEvents() {
    this.imageEl.addEventListener('click', () => this.onFallbackImageClick());
  },

  onFallbackImageClick() {
    this.videoEl.classList.add('play');

    const { src } = this.iframeEl;
    this.iframeEl.setAttribute('src', `${src}&autoPlay=1`);
  },

  init() {
    this.setStaticVariables();
    this.setDynamicVariables();

    if (this.videoEl && this.imageEl && this.iframeEl) {
      this.attachEvents();
    }
  }
};

initScript(Video);
